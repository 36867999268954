export default {
  heading: 'Kanit, serif',
  body: 'Lato, serif',
  monospace: 'Kanit, monospace',
  display: 'Kanit, cursive',
  display2: 'Kanit',
  display3: 'Kanit',
  googleFonts: ['Kanit:200,400', 'Lato:200,300,400,500,600,700,800']
  // customFamilies: [''],
  // customUrls: [''],
}
