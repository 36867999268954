export default {
  text: '#111',
  primary: '#E70000',
  secondary: '#C50000',
  tertiary: '#E70000',
  background: '#f7f7f7',
  backgroundSecondary: '#f7f7f7',
  light: '#fff',
  dark: '#111'
}
